<template>
  <div class="ticketMain">
    <div class="ticketLeft">
      <head-layout head-title="作业票流程"></head-layout>
      <div class="ticketStep">
        <el-steps direction="vertical" :active="ticketStep">
          <el-step title="申请" @click.native="runJump('draft')"></el-step>
          <el-step title="安全交底" @click.native="runJump('disclose')"></el-step>
          <el-step title="安全监管" @click.native="runJump('supervise')"></el-step>
          <el-step title="验收确认" @click.native="runJump('confirm')"></el-step>
          <el-step title="完成" @click.native="runJump('confirm')"></el-step>
        </el-steps>
      </div>
    </div>
    <div class="ticketRight">
      <div class="ticketHead">
        <head-layout head-title="作业票基本信息" :head-btn-options="headBtnOptions" @head-forward="handleForward"
                     @head-save="headSave('saveData')" @head-refer="headRefer" @head-cancel="headCancel"></head-layout>
        <el-button type="primary" class="ticketHeadBtn">
          {{ ticketForm.jobTicketName }}
        </el-button>
        <el-form :model="dataForm" label-width="100px" class="ticketHeadCode">
          <el-form-item label="作业票编号:" prop="ticketCode">
            <el-input v-model="dataForm.ticketCode" placeholder="请输入作业票编号" style="width: 300px"
                      :disabled="true"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="ticketContent">
        <div class="ticketForm" id="draft">
          <el-form :model="dataForm" :rules="rules" ref="formLayout"
                   :disabled="['view', 'flow', 'sign', 'workflow'].includes(type)" label-width="140px">
            <!--            <el-image-->
            <!--              style="width: 100%; height: 30%"-->
            <!--              :src="offlineImg.url"-->
            <!--              :preview-src-list="offlineImg.srcList">-->
            <!--            </el-image>-->
            <offlineImg :disabled="false" ref="offlineImg"></offlineImg>
            <el-form-item label="安全交底人:" prop="securityDisclosureName">
              <el-input v-model="dataForm.securityDisclosureName" placeholder="请选择安全交底人" readonly maxlength="255"
                        show-word-limit>
                <el-button slot="append" icon="el-icon-search" @click="openUser('securityDisclosureName')"></el-button>
              </el-input>
            </el-form-item>
            <el-row>
              <el-col :span="24">
                <el-form-item label="人员审核:">
                  <el-tag size="medium" :key="index" v-for="(item, index) in examPeopleList" :closable="!['view', 'flow', 'sign', 'workflow'].includes(type)"
                          :disable-transitions="false" style="margin-right: 12px;"
                          @close="deleteExamPeople(item, index)">
                    {{ item.name }}
                  </el-tag>
                  <el-button type="primary" size="mini" @click="addExamPeople">增加</el-button>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="经理审核:">
                  <el-tag size="medium" :key="index" v-for="(item, index) in examManageList" :closable="!['view', 'flow', 'sign', 'workflow'].includes(type)"
                          :disable-transitions="false" style="margin-right: 12px;"
                          @close="deleteExamManage(item, index)">
                    {{ item.name }}
                  </el-tag>
                  <el-button type="primary" size="mini" @click="addExamManage">增加</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>

        <div class="ticketGas">
          <head-layout head-title="安全监管人" :head-btn-options="superviseHeadBtn" @head-add="addSupervise"></head-layout>
          <div class="gasTable">
            <el-table :data="safetySupervisorList" border size="medium" style="width: 100%">
              <el-table-column label="序号" type="index" align="center" width="50">
              </el-table-column>
              <el-table-column prop="conservatorName" align="center" label="监管人名称">
              </el-table-column>
              <el-table-column label="操作" align="center" width="100" v-if="['edit', 'add'].includes(type)">
                <template slot-scope="scope">
                  <el-button type="text" size="small" @click="deleteSupervise(scope.row)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!-- 作业票审批 -->
        <div class="ticketGas" v-if="processFlowList.length != 0">
          <head-layout head-title="作业票审批"></head-layout>
          <div class="gasTable">
            <el-table :data="processFlowList" border size="medium" style="width: 100%">
              <el-table-column label="序号" type="index" align="center" width="50">
              </el-table-column>
              <el-table-column prop="assigneeName" align="center" label="审批人">
              </el-table-column>
              <el-table-column prop="comments" align="center" label="审批意见">
                <template slot-scope="scope">
                  <el-tag type="danger" v-if="scope.row.comments.length != 0">{{
                      scope.row.comments[0].message
                    }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="historyActivityType" align="center" label="审批结果">
                <template slot-scope="scope">
                  <el-tag>{{
                      scope.row.historyActivityType == "endEvent"
                        ? "结束"
                        : scope.row.historyActivityName || "未命名"
                    }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="endTime" align="center" label="审批时间">
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!-- 安全交底 -->
        <div class="ticketGas" id="disclose" v-if="['DISCLOSED', 'SUPERVISE', 'CHECKACCEPT', 'FINISHED'].includes(
          businessStatus
        ) && ['supervise', 'safeConfessor', 'respUser', 'drafter'].includes(characters)">
          <head-layout head-title="安全交底" :head-btn-options="discloseHeadBtn" @head-add="addDisclose"></head-layout>
          <div class="gasTable">
            <el-table :data="securityDisclosureList" border size="medium" style="width: 100%">
              <el-table-column label="序号" type="index" align="center" width="50">
              </el-table-column>
              <el-table-column prop="disclosureName" align="center" label="交底人">
              </el-table-column>
              <el-table-column prop="documentUrl" align="center" label="相关文件">
                <template slot-scope="scope" v-if="scope.row.documentUrl != ''">
                  <el-tag @click="downloadFile(item)" style="cursor: pointer;"
                          v-for="(item, index) in JSON.parse(scope.row.documentUrl)" :key="index">{{
                      item.name
                    }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="disclosureTime" align="center" label="日期时间">
              </el-table-column>
              <el-table-column label="操作" align="center" width="150">
                <template slot-scope="scope">
                  <el-button type="text" size="small" @click="editDisclose(scope.row)"
                             v-if="type == 'flow' && characters == 'safeConfessor'">
                    编辑
                  </el-button>
                  <el-button type="text" size="small" @click="deleteDisclose(scope.row)"
                             v-if="type == 'flow' && characters == 'safeConfessor'">
                    删除
                  </el-button>
                  <el-button type="text" size="small" @click="viewDisclose(scope.row)">查看</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!-- 安全监管现场照片 -->
        <div class="ticketGas" id="supervise" v-if="
          ['SUPERVISE', 'CHECKACCEPT', 'FINISHED'].includes(businessStatus) &&
          ['supervise', 'safeConfessor', 'respUser', 'drafter'].includes(
            characters
          )
        ">
          <superviseImg ref="superviseImg" :disabled="type == 'flow' && characters == 'supervise'"></superviseImg>
        </div>
        <!-- 完工确认 -->
        <div class="ticketGas" id="confirm" v-if="
          ['CHECKACCEPT', 'FINISHED'].includes(businessStatus) &&
          ['supervise', 'safeConfessor', 'respUser', 'drafter'].includes(
            characters
          )
        ">
          <finishWorkImg ref="finishWorkImg" :disabled="type == 'flow' && characters == 'respUser'"></finishWorkImg>
        </div>
      </div>
    </div>
<!--作业监管人上传的附件表-->
    <jobTicketImage ref="jobTicketImage" :title="'在线预览'"></jobTicketImage>
    <!--签名弹窗-->
    <el-dialog title="电子签名" :visible.sync="signatureShow" v-if="signatureShow" width="1000px">
      <signature @getSignatureImg="getSignatureImg"></signature>
    </el-dialog>
    <!--当前部门及一下弹窗-->
    <el-dialog title="部门选择" :visible.sync="deptBelowShow" width="1000px">
      <currentDepartmentBelow @getDeptBelow="getDeptBelow"></currentDepartmentBelow>
    </el-dialog>
    <!--当前部门及一下弹窗-->
    <el-dialog title="人员选择" :visible.sync="userShow" top="9vh" width="70%">
      <ProjectSelectUser v-if="userShow" @select-data="getUser"></ProjectSelectUser>
    </el-dialog>
    <!--安全交底表单弹窗-->
    <el-dialog title="安全交底" :visible.sync="discloseShow" top="9vh" width="40%">
      <el-form :model="discloseForm" :rules="discloseRules" ref="discloseForm" label-width="100px"
               :disabled="discloseType == 'view'">
        <el-form-item label="交底人" prop="disclosureName">
          <el-input v-model="discloseForm.disclosureName" placeholder="请选择交底人">
            <el-button slot="append" icon="el-icon-search" @click="openUser('disclosureName')"></el-button>
          </el-input>
        </el-form-item>
        <el-form-item label="日期时间" prop="disclosureTime">
          <el-date-picker v-model="discloseForm.disclosureTime" type="datetime" format="yyyy-MM-dd HH:mm:ss"
                          value-format="yyyy-MM-dd HH:mm:ss" placeholder="请选择日期时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="交底内容" prop="remark">
          <el-input type="textarea" v-model="discloseForm.remark" placeholder="请输入交底内容"></el-input>
        </el-form-item>
        <el-form-item label="相关文件" prop="documentUrl">
          <el-upload action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform"
                     :disabled="discloseType == 'view'" :headers="headers" :on-success="discloseSuc"
                     :on-remove="discloseRem"
                     :on-preview="downloadFile" multiple :limit="3" :file-list="documentUrl">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="discloseShow = false">取 消</el-button>
        <el-button type="primary" @click="submitDisclse" v-if="discloseType != 'view'">确 定</el-button>
      </span>
    </el-dialog>
    <!--当前部门及一下多选弹窗-->
    <el-dialog title="人员选择" :visible.sync="userListShow" top="9vh" width="80%">
      <UserDeptMultipleDialog ref="userDeptMultipleDialog" v-if="userListShow" @select-all-data="getUserList" @closeDia="closeDia">
      </UserDeptMultipleDialog>
    </el-dialog>
    <!--关键的其他特殊作业及安全作业票号-->
    <common-dialog dialogTitle="作业票清单" v-if="jobTicketDialogShow" width="60%" :showConfirmBtn="false"
                   @cancel="jobTicketDialogShow = false">
      <jobTicketDialog ref="jobTicketDialog" :ticketCode="dataForm.ticketCode" @submitData="submitData">
      </jobTicketDialog>
    </common-dialog>
    <!--流程用户弹框-->
    <process-user-dialog ref="processUser" @getUserSelection="handleUserSelection" @closeProcess="closeProcess"></process-user-dialog>
  </div>
</template>
<script>
import HeadLayout from "@/views/components/layout/head-layout";
import signature from "@/views/components/signature";
import currentDepartmentBelow from "@/views/components/currentDepartmentBelow";
import UserDetpDialog from "@/views/business/jobSlip/jobTicketList/components/UserDetpDialog";
import {getDictionary} from "@/api/system/dictbiz";
import {
  templateCode,
  myWtTicketDetail,
  wtBaseHeightRetransmission,
  removeWtUser,
  safetySupervisionRemove,
  securityDisclosureRemove,
  supervisionCompletionSaveOrUpdate,
  findNextProcessAssignee,
  notSubmittedForSignature,
  notSubmittedForSignatureSubmit
} from "@/api/workTicket";
import {list as security} from "@/api/safetyTtandard/jobActivity";
import CommonDialog from "@/components/CommonDialog";
import UserDeptMultipleDialog from "@/views/business/jobSlip/jobTicketList/components/UserDeptMultipleDialog";
import TemplateType from "@/views/business/jobSlip/jobSlipInventory/components/templateType";
import jobTicketDialog from "@/views/business/jobSlip/jobTicketList/jobTicketDialog";
import {detail as processDetail, processList as getProcessList} from "@/api/plugin/workflow/process";
import {mapGetters} from "vuex";
import ProcessUserDialog from "@/views/business/components/processUserDialog";
import {dateFormat} from "@/util/date";
import jobFlow from "@/views/business/jobSlip/jobTicketList/jobFlow";

import {getToken} from "@/util/auth";
import superviseImg from "@/views/business/jobSlip/jobTicketList/superviseImg";
import FinishWorkImg from "@/views/business/jobSlip/jobTicketList/finishWorkImg";
import {dictionaryBiz} from "@/api/reportTasks";
import offlineImg from "@/views/business/jobSlip/jobTicketList/offlineImg";
import jobTicketImage from "@/views/business/jobSlip/jobTicketList/jobTicketImage";
import ProjectSelectUser from "@/views/components/UserDeptDialog/projectSelectUser.vue";

export default {
  components: {
    HeadLayout,
    signature,
    currentDepartmentBelow,
    // UserDetpDialog,
    ProjectSelectUser,
    CommonDialog,
    TemplateType,
    jobTicketDialog,
    UserDeptMultipleDialog,
    ProcessUserDialog,
    superviseImg,
    FinishWorkImg,
    offlineImg,
    jobTicketImage
  },
  data() {
    return {
      rules: {
        securityDisclosureName: [
          {
            required: true,
            message: "请选择安全交底人",
            trigger: ["blur", "change"],
          },
        ],
      },
      offlineImg: {
        url: '',
        srcList: [],
      },
      processId: "",
      type: "",
      businessStatus: "",
      characters: "",
      processInstanceId: '',
      //作业票类型
      dataState: [],
      ticketForm: {
        ticketRadio: 0,
        ticketFile: '',
        jobTicketType: "gkzy",
        //作业票名称
        jobTicketName: '高空作业'
      },
      jobTicketDialogShow: false,
      dataForm: {
        securityDisclosureName:'',
        ticketCode: "",
        hightLevel: "",
        level: "",
        locationSite: "",
        respUserName: "",
        respUserId: "",
        respUserDept: "",
        securityDisclosureId: "",
        securityDisclosureCode: "",
        securityDisclosureDept: "",
        dhUserId: "",
        dhUserCode: "",
        dhUserName: "",
        guardianId: "",
        guardianCode: "",
        startTime: "",
        endTime: "",
        recognizePeople: "",
        // wtGasList: [],
        wtSecurityPreventionList: [],
        wtDisclosureList: [],
      },
      wtTypeOther: "",
      wtRiskOther: "",
      isSubmit: false,
      kictectType: false,
      tableIndex: 0,
      tableName: "",
      userOpentype: "",
      deptOpenType: "",
      signatureShow: false,
      deptBelowShow: false,
      userShow: false,
      wtSecurityPreventionList: [],
      userListShow: false,
      operatorSelect: false,
      processFlowList: [],
      safetySupervisorList: [],
      securityDisclosureList: [],
      discloseShow: false,
      discloseForm: {},
      discloseType: "add",
      documentUrl: [],
      examPeopleList: [],
      examManageList: [],
      discloseRules: {
        disclosureName: [
          {
            required: true,
            message: "请选择交底人",
            trigger: ["blur", "change"],
          },
        ],
        disclosureTime: [
          {
            required: true,
            message: "请选择日期时间",
            trigger: ["blur", "change"],
          },
        ],
        remark: [
          {
            required: true,
            message: "请输入交底内容",
            trigger: ["blur", "change"],
          },
        ],
        // documentUrl: [
        //   {required: true, message: '请选择相关文件', trigger: ['blur', 'change']},
        // ]
      },
    };
  },
  mixins: [jobFlow],
  computed: {
    ...mapGetters(["userInfo"]),
    headers: function () {
      return {"Sinoma-Auth": getToken()};
    },
    ticketStep() {
      let number = 0;
      if (
        ["PREPARE", "CONFIRMING", "CONFIRMING"].includes(this.businessStatus)
      ) {
        number = 1;
      }
      if (["DISCLOSED"].includes(this.businessStatus)) {
        number = 2;
      }
      if (["SUPERVISE"].includes(this.businessStatus)) {
        number = 3;
      }
      if (["CHECKACCEPT"].includes(this.businessStatus)) {
        number = 4;
      }
      if (["FINISHED"].includes(this.businessStatus)) {
        number = 5;
      }
      return number;
    },
    gasHeadBtn() {
      let result = [];
      if (["edit", "add"].includes(this.type)) {
        result.push({
          label: "新增",
          emit: "head-add",
          btnOptType: "add",
        });
      }
      return result;
    },
    secureHeadBtn() {
      let result = [];
      if (["edit", "add"].includes(this.type)) {
        result.push({
          label: "新增",
          emit: "head-add",
          btnOptType: "add",
        });
      }
      return result;
    },
    operatorHeadBtn() {
      let result = [];
      if (["edit", "add"].includes(this.type)) {
        result.push({
          label: "选择人员",
          emit: "head-add",
          btnOptType: "add",
        });
      }
      return result;
    },
    superviseHeadBtn() {
      let result = [];
      if (["edit", "add"].includes(this.type)) {
        result.push({
          label: "选择人员",
          emit: "head-add",
          btnOptType: "add",
        });
      }
      return result;
    },
    discloseHeadBtn() {
      let result = [];
      if (this.type == "flow" && this.businessStatus == "DISCLOSED") {
        result.push({
          label: "新增",
          emit: "head-add",
          btnOptType: "add",
        });
      }
      return result;
    },
    headBtnOptions() {
      let result = [];
      if (
        (this.type == "add" ||
          ["PREPARE", "DISCLOSED", "SUPERVISE", "CHECKACCEPT"].includes(
            this.businessStatus
          ) ||
          this.type == "sign") &&
        !["view", "workflow"].includes(
          this.type)
      ) {
        result.push({
          label: "保存",
          emit: "head-save",
          btnOptType: "save",
        });
      }

      if (
        (this.type == "add" ||
          [
            "PREPARE",
            "CONFIRMING",
            "DISCLOSED",
            "SUPERVISE",
            "CHECKACCEPT",
          ].includes(this.businessStatus)) &&
        !["view", "workflow"].includes(
          this.type)
      ) {
        result.push({
          label: "提交",
          emit: "head-refer",
          loading: this.isSubmit,
          btnOptType: "refer",
        });
      }
      if (this.type != "workflow") {
        result.push({
          label: this.$t("cip.cmn.btn.celBtn"),
          emit: "head-cancel",
          type: "button",
          icon: "",
        });
      }
      return result;
    },
  },
  created() {
  },
  mounted() {
    let {type, id, businessStatus, characters, processInstanceId, ticketForm} = this.$route.query;
    this.type = type;
    this.characters = characters;
    this.businessStatus = businessStatus;
    this.processInstanceId = processInstanceId;

    if (this.type == "add") {
      this.ticketForm = ticketForm
      console.log("this.ticketForm.ticketFile", this.ticketForm.ticketFile)
      this.$refs.offlineImg.data.ticketFile = this.ticketForm.ticketFile;
      // this.offlineImg.url = this.ticketForm.ticketFile
      // this.offlineImg.srcList.push(this.ticketForm.ticketFile)
      this.dataForm.requirTime = dateFormat(new Date());
      //作业单位默认登陆人组织
      this.dataForm.requirOrg = this.userInfo.deptName;
      this.dataForm.workOrg = this.userInfo.deptName;
      this.dataForm.respUserDept = this.userInfo.deptId;
      this.getTemplateCode();
    }
    if (type != "add") {
      this.initData(id);
    }
    if (this.processInstanceId && this.processInstanceId != ''&& this.processInstanceId != "null") {
      this.getProcessDetail();
    }
    if (this.type == 'add' || this.type == 'edit') {
      // 获取可申请流程列表
      this.jobTicketType(this.ticketForm.jobTicketType);
    }
  },
  methods: {
    //判断需要走那个流程
    jobTicketType(data){
      //高空作业
      if (data == "gkzy") {
        this.getProcess("upperAir_job")
      }
      //动土作业
      if (data == "dtzy") {
        this.getProcess("breakGround_job")
      }
      //动火作业
      if (data == "dhzy") {
        this.getProcess("loseOneSTemper_job")
      }
      //吊庄作业
      if (data == "dzzy") {
        this.getProcess("aSurname_job")
      }
      //受限空间作业
      if (data == "yxkj") {
        this.getProcess("limitation_job")
      }
      //临时用电作业
      if (data == "lsyd") {
        this.getProcess("temporarily_job")
      }
      //断路作业
      if (data == "dlzy") {
        this.getProcess("openCircuit_job")
      }
      //盲板抽堵作业
      if (data == "mbcd") {
        this.getProcess("blindPlate_job")
      }
      //爆破作业
      if (data == "bpzy") {
        this.getProcess("explode_job")
      }
      //其他作业
      if (data == "qtzy") {
        this.getProcess("other_job")
      }
    },
    // 流程详情
    getProcessDetail() {

      processDetail({processInsId: this.processInstanceId}).then((res) => {
        if (res.data.code == 200) {
          this.processFlowList = res.data.data.flow;
        }
      })
    },
    // 下载文件
    downloadFile(row) {
      this.$refs.jobTicketImage.jobTicketRow(row)
    },
    // 新增安全交底
    addDisclose() {
      this.discloseType = "add";
      this.discloseForm = {
        ticketCode: this.dataForm.ticketCode,
        remark: "",
        disclosureTime: dateFormat(new Date()),
        disclosureId: this.userInfo.id,
        disclosureName: this.userInfo.real_name,
        documentUrl: "",
      };
      this.discloseShow = true;
      this.$nextTick(() => {
        this.$refs.discloseForm.resetFields();
      });
    },
    // 编辑安全交底
    editDisclose(row) {
      this.discloseType = "edit";
      this.discloseForm = row;
      if (this.discloseForm.documentUrl == "") {
        this.documentUrl = [];
      } else {
        this.documentUrl = JSON.parse(this.discloseForm.documentUrl);
      }
      this.discloseShow = true;
    },
    // 删除安全交底
    deleteDisclose(row) {
      if (row.id) {
        this.$loading();
        securityDisclosureRemove(row.id).then((res) => {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.$loading().close();
        });
      } else {
        this.$message({
          type: "success",
          message: "操作成功",
        });
      }
      this.securityDisclosureList = this.securityDisclosureList.filter(
        (item) => item.id != row.id
      );
    },
    // 查看安全交底
    viewDisclose(row) {
      this.discloseType = "view";
      this.discloseForm = row;
      if (this.discloseForm.documentUrl == "") {
        this.documentUrl = [];
      } else {
        this.documentUrl = JSON.parse(this.discloseForm.documentUrl);
      }
      this.discloseShow = true;
    },
    // 保存安全交底
    submitDisclse() {
      this.$refs.discloseForm.validate((valid) => {
        if (valid) {

          if (this.documentUrl.length == 0) {
            this.documentUrl = "";
          } else {
            this.discloseForm.documentUrl = JSON.stringify(this.documentUrl);
          }
          if (this.discloseType == "add") {
            this.securityDisclosureList.push(this.discloseForm);
          }
          this.discloseShow = false;
        }
      });
    },
    // 上传成功
    discloseSuc(res) {
      if (res.code == 200) {
        this.documentUrl.push({
          name: res.data.originalName,
          url: res.data.link,
        });
      }
    },
    // 删除
    discloseRem(file) {
      this.documentUrl = this.documentUrl.filter(
        (item) => item.name != file.name
      );
    },
    // 新增安全监管人
    addSupervise() {
      this.userOpentype = "supervise";
      this.operatorSelect = true;
      this.userListShow = true;
      this.$nextTick(() => {
        this.$refs.userDeptMultipleDialog.selectionClear();
      });
      if (this.safetySupervisorList.length > 0) {
        let ids = [];
        this.safetySupervisorList.forEach((item) => {
          if (item.id && item.id > 0) {
            ids.push(item.id);
          }
        });
        if (ids.length > 0) {
          safetySupervisionRemove(ids.join(","));
        }
        this.safetySupervisorList = [];
      }
    },
    // 删除安全监管人
    deleteSupervise(item) {
      if (item.id && item.id > 0) {
        this.$loading();
        safetySupervisionRemove(item.id)
          .then((res) => {
            this.$message({
              type: "success",
              message: res.data.msg,
              duration: 1000,
            });
          })
          .finally(() => {
            this.$loading().close();
          });
      } else {
        this.$message({
          type: "success",
          message: "操作成功",
          duration: 1000,
        });
      }
      this.safetySupervisorList = this.safetySupervisorList.filter(
        (child) => child.conservatorId !== item.conservatorId
      );
    },
    //作业人员选择
    addOperator() {
      this.userOpentype = "user";
      this.operatorSelect = true;
      this.userListShow = true;
      this.$nextTick(() => {
        this.$refs.userDeptMultipleDialog.selectionClear();
      });
      if (this.wtUserList.length > 0) {
        let ids = [];
        this.wtUserList.forEach((item) => {
          if (item.id && item.id > 0) {
            ids.push(item.id);
          }
        });
        if (ids.length > 0) {
          removeWtUser(ids.join(","));
        }
        this.wtUserList = [];
      }
    },

    getProcess(type) {
      this.isSubmit = true;
      getProcessList(1, 99, {})
        .then((res) => {
          let process = res.data.data.records.filter(
            (item) => item.key == type
          )[0];
          this.processId = process.id;
          this.formProcess.processId = this.processId;

        })
        .finally(() => {
          this.isSubmit = false;
        });
    },

    // 查询详情数据
    async initData(id) {
      return new Promise((resolve, reject) => {
        myWtTicketDetail(id).then((res) => {
          var data = res.data.data;
          this.businessStatus = data.data.businessStatus;
          this.ticketForm.jobTicketType = data.data.ticketType;
          dictionaryBiz("work_permit_type").then((res) => {
            this.dataState = res.data.data;
            let filter = this.dataState.filter(e => e.dictKey == data.data.ticketType);
            this.ticketForm.jobTicketName = filter[0].dictValue
          });
          this.dataForm = data.data;

          this.businessStatus = this.dataForm.businessStatus;
          this.safetySupervisorList = this.dataForm.safetySupervisorList;
          this.securityDisclosureList = this.dataForm.securityDisclosureList;
          this.$refs.offlineImg.data.ticketFile = data.data.imgUrl;
          this.ticketForm.ticketFile = data.data.imgUrl
          if (this.$refs.superviseImg && this.dataForm.supervision) {
            this.$refs.superviseImg.superviseData.supervision =
              this.dataForm.supervision;
          }
          if (this.$refs.finishWorkImg && this.dataForm.completion) {
            this.$refs.finishWorkImg.finishWorkData.completion =
              this.dataForm.completion;
          }
          if (this.dataForm.examPeopleId != '') {
            let examPeopleId = this.dataForm.examPeopleId.split(',')
            let examPeopleName = this.dataForm.examPeopleName.split(',')
            this.examPeopleList = [];
            examPeopleId.forEach((item, index) => {
              this.examPeopleList.push({
                id: item,
                name: examPeopleName[index]
              })
            })
          }

          if (this.dataForm.examManageId != '') {
            let examManageId = this.dataForm.examManageId.split(',')
            let examManageName = this.dataForm.examManageName.split(',')
            this.examManageList = [];
            examManageId.forEach((item, index) => {
              this.examManageList.push({
                id: item,
                name: examManageName[index]
              })
            })
          }
          this.offlineImg.url = data.data.imgUrl;
          this.offlineImg.srcList.push(data.data.imgUrl);

          resolve(this.dataForm);
        });
      })
    },
    // 获取作业票编号
    getTemplateCode() {
      templateCode().then((res) => {
        if (res.data.code == 200) {
          this.dataForm.ticketCode = res.data.data;
        }
      });
    },
    // 打开当前用户所在部门及以下弹窗
    openDeptBelow(type) {
      this.deptOpenType = type;
      this.deptBelowShow = true;
    },
    // 选择部门
    getDeptBelow(data) {
      this.deptBelowShow = false;
      if (this.deptOpenType == "requirOrg") {
        this.dataForm.requirOrg = data.deptName;
      }
      if (this.deptOpenType == "workOrg") {
        this.dataForm.workOrg = data.deptName;
      }
    },
    //关键的其他特殊作业及安全作业票号:
    relatedWt() {
      this.jobTicketDialogShow = true;
    },
    // 打开人员选择弹窗
    openUser(type) {
      this.userOpentype = type;
      this.userShow = true;
    },
    getUserList(data) {
      if (!this.operatorSelect) {
        let userList = [];
        data.selectAllData.forEach((item) => {
          userList.push({
            userId: item.id.toString(),
            realName: item.realName,
            userDept: item.deptId,
          });
        });
        let cc = {
          ticketCode: this.dataForm.ticketCode,
          userList: userList,
        };
        wtBaseHeightRetransmission(cc).then((res) => {
          this.$message({
            type: "success",
            message: "操作成功",
            duration: 1000,
          });
        });
      } else {
        let userList = [];
        if (this.userOpentype == "user") {
          //作业人员选择逻辑
          data.selectAllData.forEach((item) => {
            userList.push({
              ticketCode: this.dataForm.ticketCode,
              userId: item.id,
              userAccount: item.account,
              userName: item.realName,
              userLicense: item.userLicense,
              userType: this.userOpentype,
              userDept: item.deptName,
            });
          });
          this.wtUserList = userList;
        }
        if (this.userOpentype == 'supervise') {
          data.selectAllData.forEach((item) => {
            userList.push({
              ticketCode: this.dataForm.ticketCode,
              conservatorId: item.id,
              conservatorName: item.realName,
              conservatorDept: item.deptId
            });
          });
          this.safetySupervisorList = userList;
        }
        if (this.userOpentype == 'examPeople') {
          data.selectAllData.forEach((item) => {
            userList.push({
              name: item.realName,
              id: item.id
            });
          });
          this.examPeopleList = userList;
        }
        if (this.userOpentype == 'examManage') {
          data.selectAllData.forEach((item) => {
            userList.push({
              name: item.realName,
              id: item.id
            });
          });
          this.examManageList = userList;
        }
      }
    },
    closeDia() {
      this.userListShow = false;
    },
    // 选择人员
    getUser(data) {
      this.userShow = false;
      if (this.userOpentype == "respNameNick") {
        this.dataForm.respUserName = data.account;
        this.dataForm.respUserId = data.userId;
        this.dataForm.respNameNick = data.userName;
        this.dataForm.respUserDept = data.deptId;
      }
      if (this.userOpentype == "user") {
        this.dataForm.userId = data.userId;
        this.dataForm.userName = data.userName;
        this.dataForm.userCode = data.certificateCode;
      }
      if (this.userOpentype == "guardian") {
        this.dataForm.guardianCode = data.code;
        this.dataForm.guardianId = data.userId;
        this.dataForm.guardianName = data.userName;
      }
      if (this.userOpentype == "securityDisclosureName") {
        this.dataForm.securityDisclosureCode = data.code;
        this.dataForm.securityDisclosureId = data.userId;
        this.dataForm.securityDisclosureName = data.userName;
        this.dataForm.securityDisclosureDept = data.deptId;
      }
      if (this.userOpentype == "disclosureName") {
        this.discloseForm.disclosureName = data.userName;
        this.discloseForm.disclosureId = data.userId;
      }
    },
    /**
     * 关键的其他特殊作业及安全作业票号选中后的数据
     */
    submitData(row) {
      this.dataForm.relatedWt = row.ticketCode;
      this.jobTicketDialogShow = false;
    },
    // 保存
    async headSave(type) {
      console.log("金酸菽粟---------",type)
      if (this.dataForm.securityDisclosureId=="") {
        this.$message.warning("请先选择安全交底人!");
        return;
      }
      if (this.safetySupervisorList.length == 0) {
        this.$message.warning("请先选择安全监管人员!");
        return;
      }
      if (this.businessStatus == 'DISCLOSED' && this.securityDisclosureList.length == 0) {
        this.$message.warning("安全交底不能为空!");
        return;
      }
      if (this.businessStatus == 'SUPERVISE' && this.$refs.superviseImg.superviseData.supervision == '') {
        this.$message.warning("现场照片不能为空!");
        return;
      }
      if (this.businessStatus == 'CHECKACCEPT' && this.$refs.finishWorkImg.finishWorkData.completion == '') {
        this.$message.warning("完工验收照片不能为空!");
        return;
      }
      let emptywtSecurity = this.wtSecurityPreventionList.filter((item) => {
        if (item.securityContent == "") {
          return item;
        }
      });
      if (emptywtSecurity.length != 0) {
        this.$message.warning("请完善安全措施数据!");
        return;
      }
      let data = JSON.parse(JSON.stringify(this.dataForm));
      data.wtSecurityPreventionList = this.wtSecurityPreventionList;
      data.safetySupervisorList = this.safetySupervisorList;
      data.securityDisclosureList = this.securityDisclosureList;

      let examPeopleId = [];
      let examPeopleName = [];
      this.examPeopleList.forEach((item) => {
        examPeopleId.push(item.id);
        examPeopleName.push(item.name);
      })
      let examManageId = [];
      let examManageName = [];
      this.examManageList.forEach((item) => {
        examManageId.push(item.id);
        examManageName.push(item.name);
      })
      data.orgId = this.userInfo.dept_id
      data.examPeopleId = examPeopleId.join(',');
      data.examPeopleName = examPeopleName.join(',');
      data.examManageId = examManageId.join(',');
      data.examManageName = examManageName.join(',');

      if (this.$refs.superviseImg) {
        data.supervision = this.$refs.superviseImg.superviseData.supervision;
      }
      if (this.$refs.finishWorkImg) {
        data.completion = this.$refs.finishWorkImg.finishWorkData.completion;
      }
      data.wtDisclosureList = [];
      data.isSubmit = type;
      data.ticketType = this.ticketForm.jobTicketType
      data.imgUrl = this.ticketForm.ticketFile
      return new Promise((resolve, reject) => {
        this.$refs.formLayout.validate((valid) => {
          if (valid) {
            this.$loading();
            supervisionCompletionSaveOrUpdate(data)
              .then((res) => {
                if (res.data.code == 200) {
                  this.initData(res.data.data.ticketCode).then((indata) => {
                    resolve(indata);
                  });
                  if (type != "submit"&&type=='saveData'){
                    this.$message.success("保存成功")
                  };
                  if (type === "submit") {
                    this.$message.success("提交成功");
                    this.headCancel();
                  }
                }
              })
              .finally(() => {
                this.$loading().close();
              });
          }
        });
      });
    },

    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },


    // 列表赋值签名图片
    getSignatureImg(imgUrl) {
      // if (this.tableName == "wtGasList") {
      //   this.wtGasList[this.tableIndex].sampleUser = imgUrl;
      // }
      if (this.tableName == "recognizePeople") {
        this.dataForm.recognizePeople = imgUrl;
        console.log(
          "this.dataForm.recognizePeople",
          this.dataForm.recognizePeople
        );
      }
      if (this.tableName == "wtSecurityPreventionList") {
        this.wtSecurityPreventionList[this.tableIndex].confirmUser = imgUrl;
      }
      this.signatureShow = false;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-step__title {
  cursor: pointer;
}
.ticketMain {
  width: 100%;
  height: 100%;
  display: flex;
}

.ticketLeft {
  width: 145px;
  height: 100%;
  border-right: 1px solid #cccccc;
  background-color: #fff;
}

.ticketLeft ::v-deep .head-layout {
  height: 46px;
  line-height: 46px;
}

.ticketRight ::v-deep .head-layout {
  height: 46px;
  line-height: 46px;
}

.ticketStep {
  width: calc(100% - 40px);
  height: 300px;
  padding: 20px;
}

.ticketRight {
  width: calc(100% - 146px);
  height: 100%;
}

.ticketContent {
  width: 100%;
  height: calc(100% - 46px);
  overflow: auto;
  scrollbar-width: none;
}

::v-deep .el-input-number .el-input__inner {
  text-align: left !important;
}

.ticketHead {
  position: relative;
}

.ticketHeadBtn {
  position: absolute;
  top: 7px;
  left: 150px;
}

.ticketHeadCode {
  position: absolute;
  top: 7px;
  left: 400px;
}

.ticketForm {
  padding: 12px;
  background-color: #ffffff;
}

.ticketGas {
  margin-top: 12px;
  background-color: #ffffff;
}

.gasTable {
  padding: 12px;
}

.ticketSecure {
  margin-top: 12px;
  background-color: #ffffff;
}

.secureTable {
  padding: 12px;
}

.ticketDisclose {
  margin-top: 12px;
  background-color: #ffffff;
}

.signatureImg {
  width: 70px;
  height: 32px;
  display: block;
  margin: auto;
}

::v-deep .el-textarea .el-input__count {
  height: 20px !important;
}
</style>
