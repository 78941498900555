var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.type != "trainingTaskList"
        ? _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c("head-layout", {
                    attrs: {
                      "head-btn-options": _vm.headBtnOptions,
                      "head-title":
                        this.$route.query.type == "edit"
                          ? "自定义课程编辑"
                          : this.$route.query.type == "view"
                          ? "自定义课程查看"
                          : "自定义课程新增",
                    },
                    on: {
                      "head-save": _vm.headSave,
                      "head-save-back": function ($event) {
                        return _vm.headSave(true)
                      },
                      "head-cancel": _vm.headCancel,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "newZtFormBox",
          staticStyle: { "background-color": "white", padding: "20px" },
        },
        [
          _c("div", { staticClass: "imgContainer" }, [
            _vm.type == "trainingTaskList" || _vm.type == "view"
              ? _c("div", [
                  _c("div", { staticClass: "imgContent" }, [
                    _vm.form.courseCover == ""
                      ? _c("img", {
                          attrs: {
                            src: require("@/assets/training/trainingDefaultPicture.png"),
                          },
                        })
                      : _c("img", { attrs: { src: _vm.form.courseCover } }),
                  ]),
                ])
              : _c(
                  "div",
                  [
                    _c(
                      "el-upload",
                      {
                        attrs: {
                          action:
                            "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform",
                          headers: _vm.headers,
                          "show-file-list": false,
                          file: "file",
                          "on-success": _vm.handleFileCoverSuccess,
                          "before-upload": _vm.beforeAvatarUpload,
                          accept: ".jpeg,.jpg,.gif,.png",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "imgContent",
                            class: _vm.showHover ? "imgHover" : "",
                            on: {
                              mouseover: function ($event) {
                                return _vm.changeHover(true)
                              },
                              mouseleave: function ($event) {
                                return _vm.changeHover(false)
                              },
                            },
                          },
                          [
                            _vm.form.courseCover == ""
                              ? _c("img", {
                                  attrs: {
                                    src: require("@/assets/training/trainingDefaultPicture.png"),
                                  },
                                })
                              : _c("img", {
                                  attrs: { src: _vm.form.courseCover },
                                }),
                            _vm.showHover
                              ? _c("el-button", { staticClass: "btn" }, [
                                  _vm._v("重新上传"),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
          ]),
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _vm.type == "trainingTaskList"
                        ? _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "课程名称" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请选择课程名称",
                                      maxlength: "255",
                                      readonly: "",
                                      disabled:
                                        this.trainingTaskListType == "edit" ||
                                        this.trainingTaskListType == "view",
                                    },
                                    on: { focus: _vm.chooseACourse },
                                    model: {
                                      value: _vm.form.courseName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "courseName", $$v)
                                      },
                                      expression: "form.courseName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "课程名称",
                                    prop: "courseName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      disabled: _vm.readonly,
                                      placeholder: "课程名称",
                                      maxlength: "255",
                                    },
                                    model: {
                                      value: _vm.form.courseName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "courseName", $$v)
                                      },
                                      expression: "form.courseName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "培训类型",
                                prop: "trainContentId",
                              },
                            },
                            [
                              _c("avue-input-tree", {
                                attrs: {
                                  checkStrictly: "",
                                  "default-expand-all": "",
                                  placeholder: "请选择内容",
                                  props: _vm.defaultProps,
                                  disabled: _vm.readonly,
                                  dic: _vm.trainingScenariosTree,
                                },
                                model: {
                                  value: _vm.form.trainContentId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "trainContentId", $$v)
                                  },
                                  expression: "form.trainContentId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "合计课时" } },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  precision: 2,
                                  step: 0.1,
                                  max: 10000,
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.totalHours,
                                  callback: function ($$v) {
                                    _vm.totalHours = $$v
                                  },
                                  expression: "totalHours",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "达标课时", prop: "meetLesson" },
                            },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  disabled: _vm.readonly,
                                  precision: 1,
                                  step: 0.5,
                                  max: 10000,
                                  "step-strictly": "",
                                  min: 0,
                                },
                                model: {
                                  value: _vm.form.meetLesson,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "meetLesson", $$v)
                                  },
                                  expression: "form.meetLesson",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "课程类型", prop: "courseType" },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { disabled: _vm.readonly },
                                  model: {
                                    value: _vm.form.courseType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "courseType", $$v)
                                    },
                                    expression: "form.courseType",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: "1" } }, [
                                    _vm._v("继续教育"),
                                  ]),
                                  _c("el-radio", { attrs: { label: "2" } }, [
                                    _vm._v("场景培训"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "课程简介", prop: "remark" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.readonly,
                                  placeholder: "说明",
                                  type: "textarea",
                                  maxlength: "300",
                                  "show-word-limit": "",
                                  rows: 3,
                                },
                                model: {
                                  value: _vm.form.remark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "remark", $$v)
                                  },
                                  expression: "form.remark",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "视频", name: "video" } },
            [
              _c("head-layout", {
                attrs: {
                  "head-btn-options": _vm.subTableheadBtnOptions,
                  "head-title": "",
                },
                on: {
                  "head-remove": _vm.headRemove,
                  "head-add-tabs": _vm.subTableAdd,
                  "head-knowledge": _vm.headKnowledge,
                  "head-save": _vm.headSaveVideo,
                },
              }),
              _c("grid-layout", {
                ref: "gridLayoutVide",
                staticClass: "serviceType",
                attrs: {
                  "grid-row-btn": _vm.gridRow,
                  "table-options": _vm.subTableOption,
                  "table-data": _vm.videdata,
                  "table-loading": false,
                },
                on: {
                  "gird-handle-select-click": _vm.selectionChange,
                  "row-view": _vm.rowView,
                  "row-remove": _vm.rowRemove,
                  "row-add": _vm.rowAdd,
                },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "文档", name: "file" } },
            [
              _c("head-layout", {
                attrs: {
                  "head-btn-options": _vm.subTableheadBtnOptions,
                  "head-title": "",
                },
                on: {
                  "head-remove": _vm.headRemove,
                  "head-save": _vm.headSaveFile,
                  "head-add-tabs": _vm.subTableAddHeadCite,
                  "head-knowledge": _vm.coursewareHeadKnowledge,
                },
              }),
              _c("grid-layout", {
                ref: "coursewareCrud",
                staticClass: "serviceType",
                attrs: {
                  "grid-row-btn": _vm.coursewareGridRowBtn,
                  "table-options": _vm.coursewareTableOption,
                  "table-data": _vm.coursewareData,
                  "table-loading": false,
                },
                on: {
                  "gird-handle-select-click": _vm.selectionChange,
                  "row-view": _vm.rowView,
                  "row-remove": _vm.rowRemove,
                  "row-add": _vm.rowAdd,
                },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "试题", name: "question" } },
            [
              _c("head-layout", {
                attrs: {
                  "head-title": "",
                  "head-btn-options": _vm.testItemContent,
                },
                on: {
                  "head-remove": _vm.headRemove,
                  "head-cite": _vm.testQuestionsHeadCite,
                },
              }),
              _c("grid-layout", {
                ref: "crud",
                staticClass: "serviceType",
                attrs: {
                  "grid-row-btn": _vm.gridRowBtn,
                  "table-options": _vm.testQuestionssubTableOption,
                  "table-data": _vm.testQuestionsData,
                  "table-loading": false,
                },
                on: {
                  "gird-handle-select-click": _vm.selectionChange,
                  "row-view": _vm.rowViewTestQuestions,
                  "row-remove": _vm.rowRemove,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showKnowledgeDialog
        ? _c(
            "CommonDialog",
            {
              staticClass: "paperDialog knowledge",
              attrs: { width: "60%", dialogTitle: "选择知识" },
              on: {
                cancel: _vm.closeKnowledgeDialog,
                confirm: _vm.confirmKnowledge,
              },
            },
            [
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c("CommonTree", {
                    staticStyle: { "flex-shrink": "0" },
                    attrs: {
                      treeData: _vm.treeData,
                      defaultProps: _vm.defaultProps,
                      loading: _vm.treeLoading,
                      searchTitle: _vm.searchTitle,
                      showCheckbox: false,
                      treeTitle: "知识库",
                    },
                    on: { getNodeClick: _vm.repositoryTreeNodeClick },
                  }),
                  _c(
                    "div",
                    { staticStyle: { width: "calc(100% - 280px)" } },
                    [
                      _c("grid-layout", {
                        ref: "gridKnowledgeLayOut",
                        attrs: {
                          "table-options": _vm.tableKnowledgeOptions,
                          "table-data": _vm.tableKnowledgeData,
                          "table-loading": _vm.tableKnowledgeLoading,
                          "data-total": _vm.pageKnowledge.total,
                          page: _vm.pageKnowledge,
                          selectable: _vm.selectable,
                        },
                        on: {
                          "page-current-change": _vm.onLoadKnowledge,
                          "page-size-change": _vm.onLoadKnowledge,
                          "page-refresh-change": _vm.onLoadKnowledge,
                          "gird-handle-select-click":
                            _vm.selectionKnowledgeChange,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm.showExaminationDialog
        ? _c(
            "CommonDialog",
            {
              staticClass: "paperDialog",
              attrs: { width: "80%", dialogTitle: "选择试卷" },
              on: { cancel: _vm.closeDialog, confirm: _vm.confirm },
            },
            [
              _c(
                "el-container",
                [
                  _c("CommonTree", {
                    attrs: {
                      titleShow: false,
                      treeData: _vm.examinationTreeData,
                      defaultProps: _vm.examinationDefaultProps,
                      searchTitle: _vm.searchTitle,
                      showCheckbox: false,
                    },
                    on: { getNodeClick: _vm.treeNodeClick },
                  }),
                  _c(
                    "el-main",
                    [
                      _c("grid-head-layout", {
                        ref: "gridHeadLayout",
                        attrs: { "search-columns": _vm.searchColumns },
                        on: {
                          "grid-head-search": _vm.gridHeadSearch,
                          "grid-head-empty": _vm.gridHeadEmpty,
                        },
                      }),
                      _c("grid-layout", {
                        ref: "gridLayOut",
                        staticClass: "paperCurd",
                        attrs: {
                          "table-options": _vm.tableOptions,
                          "table-data": _vm.tableData,
                          "table-loading": _vm.tableLoading,
                          "data-total": _vm.paperPage.total,
                          page: _vm.paperPage,
                          selectable: _vm.selectable,
                        },
                        on: {
                          "page-current-change": _vm.onLoad,
                          "page-size-change": _vm.onLoad,
                          "page-refresh-change": _vm.onLoad,
                          "gird-handle-select-click": _vm.selectionChange,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showFileDialog
        ? _c(
            "CommonDialog",
            {
              attrs: {
                showConfirmBtn: false,
                width: "70%",
                dialogTitle: _vm.fileType == "file" ? "文档预览" : "视频预览",
              },
              on: {
                cancel: function ($event) {
                  _vm.showFileDialog = false
                },
              },
            },
            [
              _c("iframe", {
                ref: "fileIframe",
                staticStyle: { width: "100%", height: "500px" },
                attrs: { src: _vm.fileUrl, frameborder: "0" },
              }),
            ]
          )
        : _vm._e(),
      _vm.showVideo
        ? _c(
            "CommonDialog",
            {
              attrs: { width: "35%", dialogTitle: "上传" },
              on: {
                cancel: function ($event) {
                  _vm.showVideo = false
                },
                confirm: _vm.videoSubmission,
              },
            },
            [
              _vm.showVideo
                ? _c(
                    "el-form",
                    {
                      ref: "rulesPopups",
                      attrs: {
                        model: _vm.form,
                        rules: _vm.rulesPopups,
                        "label-width": "120px",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label:
                                      _vm.dialogType == "courseware"
                                        ? "课件名称"
                                        : "课程名称",
                                    prop: "courseName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "240px" },
                                    attrs: {
                                      placeholder:
                                        _vm.dialogType == "courseware"
                                          ? "课件名称"
                                          : "课程名称",
                                      maxlength: "50",
                                    },
                                    model: {
                                      value: _vm.formVideo.fileName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formVideo, "fileName", $$v)
                                      },
                                      expression: "formVideo.fileName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label:
                                      _vm.dialogType == "courseware"
                                        ? "课件上传"
                                        : "课程上传",
                                  },
                                },
                                [
                                  _c("SingleFIleUpload", {
                                    ref: "SingleFIleUpload",
                                    attrs: {
                                      accept: "",
                                      fileSize: "2000",
                                      beforeUpload: _vm.beforeUpload,
                                    },
                                    on: {
                                      fileIds: _vm.handleAvatarSuccess,
                                      handleRemove: _vm.handleRemove,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("video", {
        ref: "video",
        staticStyle: { display: "none" },
        attrs: { id: "myVideo", src: _vm.videoUrl, crossOrigin: "anonymous" },
      }),
      _c("canvas", {
        staticStyle: { display: "none" },
        attrs: { id: "myCanvas", crossOrigin: "anonymous" },
      }),
      _vm.showKnowledgeSearchDialog
        ? _c(
            "el-dialog",
            {
              attrs: {
                top: "9vh",
                title: "知识库选择",
                visible: _vm.showKnowledgeSearchDialog,
                width: "80%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showKnowledgeSearchDialog = $event
                },
              },
            },
            [
              _c("KnowledgeSearchDialog", {
                ref: "knowledgeSearchDialog",
                attrs: {
                  dialogType: _vm.dialogType,
                  selectable: _vm.knowledgeSelectable,
                },
                on: {
                  "close-dialog": _vm.closeKnowledgeSearchDialog,
                  "select-data": _vm.selectData,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }